import React from "react";
import { FacebookProvider, Page, Group, EmbeddedPost } from "react-facebook";
import "./fb.css";
import background from "./light-yellow.jpg";

const Fbpost = () => {
  return (
    <div
      className="fb-container"
      id="facebook"
      style={{ backgroundImage: `url(${background})` }}
    >
      <h4 className="fb-title">Follow us on Facebook</h4>
      <a
        href="https://www.facebook.com/diamondbeauty.blenheim.2020"
        target="_blank"
        style={{
          fontSize: "1.5rem",
          fontWeight: 600,
          marginBottom: "2rem",
          textDecoration: "none",
          color: "#000",
        }}
      >
        #diamondnailsbeauty
      </a>

      <FacebookProvider appId="211013638396718">
        <Page
          href="https://www.facebook.com/diamondnailsblenheim2020/"
          tabs="timeline"
          width={500}
          height={750}
        />
        {/* <Group
          href="https://www.facebook.com/groups/375934682909754"
          width="500"
          showSocialContext={true}
          showMetaData={true}
          skin="dark"
        /> */}
        {/* 
        <EmbeddedPost
          href="https://www.facebook.com/photo?fbid=384204737153324&set=a.384206390486492"
          width="500"
        /> */}
      </FacebookProvider>
    </div>
  );
};

export default Fbpost;
