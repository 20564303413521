import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Column, Row } from '../../globalStyles';

export const FooterRow = styled(Row)`
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 10px 30px;
	color: #fff;
	margin: auto;
	max-width: 1280px;
	// border: 1px solid green;
	@media screen and (max-width: 720px) {
		justify-content: center;
	}
`;

export const FooterRowHero = styled(Row)`
	flex-wrap: wrap;
	justify-content:center;
	padding: 30px 30px;
	color: #fff;
	margin: auto;
	margin-top: 2rem;
	max-width: 800px;
	// border: 1px solid green;
	@media screen and (max-width: 720px) {
		display: flex;
		justify-content: space-between;
		align-item: center;
		padding: 0px 00px;
	}
`;


export const FooterColumn = styled(Column)`
	margin: 1rem 2rem;
	@media screen and (max-width: 720px) {
		width: 30%;
		text-align: center;
	}
`;

export const FooterColumnHero = styled(Column)`
	margin: 1rem 2rem;
	// border: 1px solid red;
	text-align: center;
	@media screen and (max-width: 720px) {
		margin: 1rem 1.5rem;
		text-align: center;
		max-width: 30%;
		// border: 1px solid red;
	}
`;

export const SocialIcon = styled.img`
	margin-right: 10px;
	width: 40px;
`;

export const FooterSocialIcon = styled.a`
	color: #fff;
	font-size: 24px;
`;

export const FooterForm = styled.form`
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 820px) {
		flex-direction: column;
		width: 80%;
	}
`;

export const FooterInput = styled.input`
	padding: 10px 20px;
	border-radius: 2px;
	margin-right: 10px;
	outline: none;
	border: none;
	font-size: 16px;
	border: 1px solid #fff;

	&::placeholder {
		color: #242424;
	}

	@media screen and (max-width: 820px) {
		width: 100%;
		margin: 0 0 16px 0;
	}
`;

export const FooterLine = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 40px auto 0;

	@media screen and (max-width: 820px) {
		flex-direction: column;
	}
`;

export const FooterLogo = styled(Link)`
	color: #fff;
	justify-self: start;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
`;

export const WebsiteRights = styled.small`
	color: #fff;
	margin-bottom: 16px;
`;
