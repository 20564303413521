const menu = [
  {
    id: 1,
    title: 'Hair Spa (60 minutes) ',
    category: 'Hair Spa',
    price: 90,
    img: './images/item-1.jpeg',
    desc: ``,
  },
  {
    id: 2,
    title: 'Hair Spa (75 minutes) ',
    category: 'Hair Spa',
    price: 110,
    img: './images/item-2.jpeg',
    desc: ``,
  },
  {
    id: 3,
    title: 'Chin ',
    category: 'Waxing',
    price: 10,
    img: './images/item-3.jpeg',
    desc: ``,
  },
  {
    id: 4,
    title: 'Upper Lip ',
    category: 'Waxing',
    price: 8,
    img: './images/item-4.jpeg',
    desc: ``,
  },
  {
    id: 5,
    title: 'Eyebrow ',
    category: 'Waxing',
    price: 10,
    img: './images/item-5.jpeg',
    desc: ``,
  },
  {
    id: 6,
    title: 'Under Arm ',
    category: 'Waxing',
    price: 15,
    img: './images/item-6.jpeg',
    desc: ``,
  },
  {
    id: 7,
    title: 'Full Arm ',
    category: 'Waxing',
    price: 55,
    img: './images/item-7.jpeg',
    desc: ``,
  },
  {
    id: 8,
    title: 'Full Leg ',
    category: 'Waxing',
    price: 65,
    img: './images/item-8.jpeg',
    desc: ``,
  },
  {
    id: 9,
    title: 'Bikini',
    category: 'Waxing',
    price: 40,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 10,
    title: 'Brazillian',
    category: 'Waxing',
    price: 'From $60',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 11,
    title: 'Classic Full ',
    category: 'Brows & Lashes',
    price: '80',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 111,
    title: 'Light Hybrid Full',
    category: 'Brows & Lashes',
    price: '85',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 12,
    title: 'Hybrid Full ',
    category: 'Brows & Lashes',
    price: '90',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 13,
    title: 'Volume Full ',
    category: 'Brows & Lashes',
    price: '100',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 131,
    title: 'Wispy Full ',
    category: 'Brows & Lashes',
    price: '100',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 141,
    title: 'Light Mega Full ',
    category: 'Brows & Lashes',
    price: '105',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 14,
    title: 'Mega Full ',
    category: 'Brows & Lashes',
    price: '110',
    img: './images/item-9.jpeg',
    desc: ``,
  },

  {
    id: 15,
    title: 'Brow Lamination ',
    category: 'Brows & Lashes',
    price: '65',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 16,
    title: 'Lash Lift & Tint ',
    category: 'Brows & Lashes',
    price: '80',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 17,
    title: 'Head /Neck & Shoulder/Back ( 60/75/90 minutes)',
    category: 'Massage',
    price: '90/110/130',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 18,
    title: 'Reflexology & leg massage (30/45/60 minutes)',
    category: 'Massage',
    price: '50/75/90',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 191,
    title: 'Body relaxation massage(60/75/90 minutes)   ',
    category: 'Massage',
    price: '90/110/130',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 192,
    title: 'Body acupressure massage(60/75/90 minutes)',
    category: 'Massage',
    price: '90/110/130',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 193,
    title: 'Body stretching massage(60/75/90 minutes)',
    category: 'Massage',
    price: '90/110/130',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 20,
    title: 'Body massage with hot stone(60/75/90 minutes)',
    category: 'Massage',
    price: '100/120/140',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 21,
    title: 'Micro-blading/ Feather Touch Brows  ',
    category: 'Cosmetic Tattoo',
    price: 450,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 22,
    title: 'Machine/ Powder Brow   ',
    category: 'Cosmetic Tattoo',
    price: 450,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 23,
    title: '18 Months Touch Up   ',
    category: 'Cosmetic Tattoo',
    price: 150,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 24,
    title: 'Combo manicure and spa pedicure with regular ',
    category: 'Package',
    price: 75,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 25,
    title: 'Combo Manicure and Spa Pedicure with Gel polish    ',
    category: 'Package',
    price: 110,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 26,
    title: 'Henna Brows',
    category: 'Package',
    price: 60,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 27,
    title: 'Brow Lamination & Tint    ',
    category: 'Package',
    price: 70,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  
];
export default menu;
