const menu = [
  {
    id: 1,
    title: 'Manicure with Regular',
    category: 'Regular polish',
    price: 35,
    img: './images/item-1.jpeg',
    desc: '',
  },
  {
    id: 2,
    title: 'Spa pedicure with Regular ',
    category: 'Regular polish',
    price: 50,
    img: './images/item-2.jpeg',
    desc: ``,
  },
  {
    id: 4,
    title: 'Manicure with Gel polish ',
    category: 'Gel polish',
    price: 50,
    img: './images/item-4.jpeg',
    desc: ``,
  },
  {
    id: 5,
    title: 'Spa pedicure with Gel polish ',
    category: 'Gel polish',
    price:65,
    img: './images/item-5.jpeg',
    desc: ``,
  },
  {
    id: 6,
    title: 'Dipping Powder on natural nails ',
    category: 'Dipping Powder',
    price: 65,
    img: './images/item-6.jpeg',
    desc: ``,
  },
  {
    id: 7,
    title: 'Dipping Powder with Extensions ',
    category: 'Dipping Powder',
    price: 75,
    img: './images/item-7.jpeg',
    desc: ``,
  },
  {
    id: 9,
    title: 'Acrylics Nails ',
    category: 'Acrylic ',
    price: 85,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 10,
    title: 'Acrylics Nails Infill ',
    category: 'Acrylic ',
    price: '70',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 11,
    title: 'Removal of dipping powder or Acrylic ',
    category: 'Others ',
    price: '25',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 12,
    title: 'Removal of gel on nails ',
    category: 'Others ',
    price: '20',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 13,
    title: 'Nail Art ',
    category: 'Others ',
    price: '2/finger',
    img: './images/item-9.jpeg',
    desc: ``,
  },
];
export default menu;
