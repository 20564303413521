import React from 'react';

import { GiPartyFlags, GiMicrophone, GiCutDiamond } from 'react-icons/gi';
import { AiFillStar, AiOutlineHeart, AiFillHeart } from 'react-icons/ai';


import { TiGroupOutline } from 'react-icons/ti';

const iconStyle = (Icon) => <Icon size="5rem" />;

export const featuresData = {
	title: '',
	text: "",
	content: [
		{
			name: 'We are professional',
			description: '',
			icon: iconStyle(GiCutDiamond),
			image: './images/diamond.png',
		},
		{
			name: 'We are passion',
			description: '',
			icon: iconStyle(AiFillHeart),
			image: './images/kiss.png',
		},
		{
			name: 'We bring satisfaction',
			description: '',
			icon: iconStyle(AiFillStar),
			image: './images/star.png',
		},
	],
};
