import React from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";
const Fbchat = () => {
  return (
    <div>
      {/* <MessengerCustomerChat pageId="106279360798692" appId="910231926724497" /> */}
      <MessengerCustomerChat pageId="110040317228641" appId="211013638396718" themeColor={'#ca7100'} minimized={'true'}/>

    </div>
  );
};

export default Fbchat;
