import React from 'react';
import Content from '../../components/Content/Content';
import Hero from '../../components/Hero/Hero';
import Pricing from '../../components/Pricing/Pricing';
import Features from '../../components/Features/Features';
import Nails from '../../components/NailsMenu/Nails';

import { heroOne, heroTwo, heroThree } from '../../data/homeData';
import Beauty from '../../components/BeautyMenu/Beauty';
import Fbchat from '../../components/Fbchatplusgin/Fbchat';
import Fbpost from '../../components/Fbchatplusgin/Fbpost';
import Instagram from '../../components/Fbchatplusgin/instagram';
import Location from '../../components/Location/Location';

const HomePage = () => {
	return (
		<>
			<Hero />
			<Location />
			{/* <Content {...heroOne} /> */}
			<Features  {...heroOne}/>
			<Nails />
			<Beauty />
			{/* <Content {...heroTwo} />
			<Content {...heroThree} /> */}
			{/* <Pricing /> */}
			<Fbchat />
			<Fbpost />
			<Instagram />
		</>
	);
};

export default HomePage;
