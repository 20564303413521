import React from 'react';
import { Button, Column, Container, Row, Section, TextWrapper } from '../../globalStyles';
import {
	FooterForm,
	FooterInput,
	FooterLogo,
	SocialIcon,
	WebsiteRights,
	FooterColumn,
	FooterSocialIcon,
	FooterRow,
	FooterLine,
} from './FooterStyles';
import { footerData, footerSocialData } from '../../data/footerData';

function Footer() {
	return (
		<Section inverse padding="4rem 0 2rem" id="contact us">
			<Column justify="center" align="center" textAlign="center" mb="1.2rem" padding="1.2rem">
				<TextWrapper size="2rem" weight="700" mb="1.2rem" smSize="1.2rem">
				Call us: <a href='tel: +6435784890' style={{color: "#d4af37", textDecoration:"none"}}>+64 35784890 </a>
				</TextWrapper>
				<TextWrapper size="2rem" weight="700" mb="1.2rem" smSize="1.2rem">
				Email us: <a href='mailto:diamondnailsblenheim@gmail.com' style={{color: "#d4af37", textDecoration:"none"}}>diamondnailsblenheim@gmail.com </a>
				</TextWrapper>
				<TextWrapper mb="1rem" spacing="1px">
				Our companies located at 14 Maxwell road, Blenheim: AN BEAUTY LTD, JN BEAUTY LTD, NTH BEAUTY LTD				
				</TextWrapper>
				<TextWrapper mb="1rem" spacing="1px">
				We pay attention to every little detail to make your visit comfortable, so please get in touch to book the treatments that feel right for you.
				</TextWrapper>

				{/* <FooterForm>
					<FooterInput name="email" type="email" placeholder="Your Email" />
					<Button fontBig>Subscribe</Button>
				</FooterForm> */}
			</Column>
			<FooterRow>
				{footerData.map((el, index) => (
					<FooterColumn gap="0.5rem" key={index}>
						<TextWrapper weight="300" size="1rem" mb="0rem">
							{el.town}
						</TextWrapper>
						<TextWrapper weight="600" size="1.3rem" mb="0.4rem">
							{el.title}
						</TextWrapper>
						{el.links.map((link, linkIndex) => (
							<TextWrapper key={linkIndex}>{link}</TextWrapper>
						))}
					</FooterColumn>
				))}
			</FooterRow>
			<Container>
				<FooterLine>
					<FooterLogo to="/">
						<SocialIcon src="./images/diamond_full.png" style={{width: "200px"}}/>
						
					</FooterLogo>
					<WebsiteRights><strong>Diamond Nail Beauty</strong> © 2023 designed by <a target="_blank" href='https://miraelite.com/' style={{textDecoration: "none", color:"#d4af37", fontWeight:600}}>Mira Elite</a> with ❤️</WebsiteRights>
					<Row justify="center" gap="2rem" align="center" width="240px">
						{footerSocialData.map((social, index) => (
							<FooterSocialIcon
								key={index}
								href={social.link}
								target="_blank"
								aria-label={social.name}
							>
								{social.icon}
							</FooterSocialIcon>
						))}
					</Row>
				</FooterLine>
			</Container>
		</Section>
	);
}

export default Footer;
