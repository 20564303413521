import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Column, Row } from '../../globalStyles';

export const FooterRow = styled(Row)`
	flex-wrap: wrap;
	justify-content: center;
	padding: 40px 00px;
	color: #fff;
	margin: auto;
	max-width: 1280px;
	// border: 1px solid green;
	@media screen and (max-width: 720px) {
		justify-content: center;
	}
`;

export const FooterRowAdd = styled(Row)`
	flex-wrap: wrap;
	justify-content: center;
	padding: 40px 0px;
	color: #fff;
	margin: auto;
	max-height: 250px;
	max-width: 11rem;
	@media screen and (max-width: 720px) {
		justify-content: center;
	}
`;

export const FooterRowAdd2 = styled(Row)`
	flex-wrap: wrap;
	justify-content: center;
	padding: 40px 0px;
	color: #fff;
	margin: auto;
	max-height: 100px;
	max-width: 11rem;
	@media screen and (max-width: 720px) {
		justify-content: center;
		padding: 20px;
		// border: 1px solid green;
	}
`;




export const FooterColumn = styled(Column)`
	margin: 1rem 1rem;
	padding: 1rem;
	width: 16rem;
	box-shadow: 0 0 0px #fed693, 0 0 0px #fed693, 0 0 00px #fed693,
	0 0 10px #fed693;
//   -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
	@media screen and (max-width: 720px) {
		width: 18rem;
		text-align: center;
	}
`;


export const SocialIcon = styled.img`
	margin: 0 auto;
	width: 40px;
`;

export const FooterSocialIcon = styled.a`
	color: #fff;
	font-size: 24px;
	margin: 1rem auto;
`;

export const FooterForm = styled.form`
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 820px) {
		flex-direction: column;
		width: 80%;
	}
`;

export const FooterInput = styled.input`
	padding: 10px 20px;
	border-radius: 2px;
	margin-right: 10px;
	outline: none;
	border: none;
	font-size: 16px;
	border: 1px solid #fff;

	&::placeholder {
		color: #242424;
	}

	@media screen and (max-width: 820px) {
		width: 100%;
		margin: 0 0 16px 0;
	}
`;

export const FooterLine = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 40px auto 0;

	@media screen and (max-width: 820px) {
		flex-direction: column;
	}
`;

export const FooterLogo = styled(Link)`
	color: #fff;
	justify-self: start;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
`;

export const WebsiteRights = styled.small`
	color: #fff;
	margin-bottom: 16px;
`;
