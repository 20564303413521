import styled from 'styled-components';
import { Row, Section } from '../../globalStyles';
import { orange } from '../../Colors';
import { motion } from 'framer-motion';


export const HeroSection = styled(Section)`

	// old section ---------------
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
		url('/images/nail_hero.jpg');
		background-size: cover;
		-webkit-animation: slidein 20s;
		animation: slidein 20s;
	  
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
	  
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	  
		-webkit-animation-direction: alternate;
		animation-direction: alternate;


	padding-top: 200px;
	height: 100vh;
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	display: flex;

	@media screen and (max-width: 1200px) {
		padding-top: 100px;
		height: 100vh;
	}

	@media screen and (max-width: 768px) {
		padding-top: 100px;
		height: 100vh;
	}

	@-webkit-keyframes slidein {
		from {
		  background-position: top;
		  background-size: 2000px;
		}
		to {
		  background-position: -200px 100px;
		  background-size: 1750px;
		}
	  }
	
	  @keyframes slidein {
		from {
		  background-position: top;
		  background-size: 2000px;
		}
		to {
		  background-position: -800px 0px;
		  background-size: 1750px;
		}
	  }
`;

export const ButtonContainer = styled(Row)`
	justify-content: center;
	flex-flow: wrap;
	margin-bottom: 2rem;

	button {
		background-color: transparent;
		margin: 0 0.3rem;
	}
	button:nth-child(1) {
		border: 2px solid ${orange};

		&:hover {
			border: 2px solid transparent;
		}
	}

	button:nth-child(2) {
		padding: 8px 32px;

		display: flex;
		align-items: center;

		&:hover {
			background-color: transparent;
			color: ${orange};
		}
		> svg {
			margin-right: 0.4rem;
		}
	}
`;

export const HeroButton = styled(motion.button)`
	height: 3rem;
	padding: 16px 32px;
	font-weight: 700;
	font-size: 0.8rem;
	line-height: 18px;
	letter-spacing: 1.54px;
	text-transform: uppercase;
	cursor: pointer;
	background: none;
	color: ${({ inverse }) => (inverse ?  '#d4af37' : 'black')};
	border-radius: 4px;
	white-space: nowrap;
	padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
	font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
	outline: none;
	border: 1px solid #d4af37;
	cursor: pointer;
	position: relative;
	overflow: hidden;

	&:before {
		background: linear-gradient(-45deg, #fed693, #ca7100);
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
		transition: all 0.6s ease;
		width: 0%;
		height: 100%;
	}

	&:hover:before {
		width: 200%;
	}

	&:hover {
		color: ${({ inverse }) => (inverse ? 'white' : 'black')};
	}
`;