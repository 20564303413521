import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';
import {fresha_url} from '../fresha'

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
	{
		name: 'Facebook',
		icon: iconStyle(FaFacebook),
		link: 'https://www.facebook.com/diamondbeauty.blenheim.2020'
	},
	{
		name: 'Instagram',
		icon: iconStyle(FaInstagram),
		link: 'https://www.instagram.com/diamondnailsblenheim2020/'
	},
	// {
	// 	name: 'YouTube',
	// 	icon: iconStyle(FaYoutube),
	// 	link: 'https://www.youtube.com/'
	// },
	// {
	// 	name: 'Twitter',
	// 	icon: iconStyle(FaTwitter),
	// 	link: 'https://twitter.com/'
	// },
	// {
	// 	name: 'LinkedIn',
	// 	icon: iconStyle(FaLinkedin),
	// 	link: 'https://nz.linkedin.com/'
	// },
];

export const footerData = [
	{
		town: 'BLEMHEIM',
		title: 'Diamond Nails',
		links: ['14 Maxwell Road', 'Blenheim',],
		tel_no:'+64 3578 4890',
		tel: +6435784890,
		icon: iconStyle(FaFacebook),
	
	},
	{
		town: 'BLEMHEIM',
		title: 'Diamond Massage & Beauty',
		links: ['13 Charles Street', 'Blenheim'],
		tel_no:'+64 21 0890 7408',
		tel: +642108907408,
		icon: iconStyle(FaFacebook),

	},
	{
		town: 'PICTON',
		title: 'Diamond Massage & Beauty',
		links: ['3/6 London Quay ', 'Picton, Marlborough'],
		tel_no:'03-573 5220',
		tel: +645735220,
		icon: iconStyle(FaFacebook),

	},
	{
		town: 'KAIKOURA ',
		title: 'Diamond Massage & Beauty',
		links: ['2/78 Beach Road', 'Kaikoura '],
		icon: iconStyle(FaFacebook),
		tel_no:'03-319 3336',
		tel_link: 'tel: +643193336',
	},
];

export const LocationData = [
	{
		town: 'BLENHEIM',
		title: 'Diamond Nails',
		links: ['14 Maxwell Road', ', Blenheim','Mon-Sun 9am - 7pm' ],
		tel_no:'+64 3578 4890',
		tel_link: 'tel: +6435784890',
		icon: iconStyle(FaFacebook),
		email:'',
		fb: 'https://www.facebook.com/diamondbeauty.blenheim.2020',
		fresha:'https://www.fresha.com/providers/diamond-nails-blenheim-gi07lz8m?fbclid=IwAR2WGE4-kHx9NVZ2AOo0DQlctbXXRLOQ1qgvzSCmZPXFBjluFvJUwYTwkBI&allOffer=true'
	},
	{
		town: 'BLENHEIM',
		title: 'Diamond Massage & Beauty',
		links: ['13 Charles Street', ', Blenheim','Mon-Sun 9am - 7pm'],
		tel_no:'+64 3927 3297',
		tel_link: 'tel: +6439273297',
		email:'',
		icon: iconStyle(FaFacebook),
		fb: 'https://www.facebook.com/diamondmassageandbeautyblenheim/',
		fresha:'https://www.fresha.com/providers/diamond-nails-blenheim-gi07lz8m?fbclid=IwAR2WGE4-kHx9NVZ2AOo0DQlctbXXRLOQ1qgvzSCmZPXFBjluFvJUwYTwkBI&allOffer=true'
	},
	{
		town: 'PICTON',
		title: 'Diamond Massage & Beauty',
		links: ['3/6 London Quay ', 'Picton, Marlborough','Mon-Sun 9am - 7pm'],
		tel_no:'+64 3573 5220',
		tel_link: 'tel: +6435735220',
		email:'diamondbeautypicton@gmail.com',
		email_link:'mailto:diamondbeautypicton@gmail.com',
		icon: iconStyle(FaFacebook),
		fb: 'https://www.facebook.com/diamondbeautypicton/',
		fresha:'https://www.fresha.com/providers/diamond-nails-blenheim-gi07lz8m?fbclid=IwAR2WGE4-kHx9NVZ2AOo0DQlctbXXRLOQ1qgvzSCmZPXFBjluFvJUwYTwkBI&allOffer=true'
	},
	{
		town: 'KAIKOURA ',
		title: 'Diamond Massage & Beauty',
		links: ['2/78 Beach Road', ', Kaikoura ','Mon-Sun 9am - 7pm'],
		icon: iconStyle(FaFacebook),
		tel_no:'+64 3319 3336',
		tel_link: 'tel: +6433193336',
		email:'diamondbeautykaikoura@gmail.com',
		email_link:'mailto:diamondbeautykaikoura@gmail.com',

		fb: 'https://www.facebook.com/diamondbeautykaikoura',
		fresha:'https://www.fresha.com/providers/diamond-nails-blenheim-gi07lz8m?fbclid=IwAR2WGE4-kHx9NVZ2AOo0DQlctbXXRLOQ1qgvzSCmZPXFBjluFvJUwYTwkBI&allOffer=true'
	},
];


