import React, { useEffect, useState } from "react";
import {
  Button,
  Column,
  Container,
  Row,
  Section,
  TextWrapper,
} from "../../globalStyles";
import {
  FooterForm,
  FooterInput,
  FooterLogo,
  SocialIcon,
  WebsiteRights,
  FooterColumn,
  FooterSocialIcon,
  FooterRow,
  FooterLine,
  FooterRowAdd,
  FooterRowAdd2
} from "./LocationStyles";
import { footerData, footerSocialData,LocationData} from "../../data/footerData";
import background from "./black-paper-3.jpg";
import {
	ContentButton,
	ContentHeading,
	Subtitle,
	ImgWrapper,
	Img,
	ContentColumn,
  } from "./ContentStyles";
  import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Location() {
	

  return (
    <Section inverse padding="0rem 0 0rem" id="location" style={{background: `url(${background})`}}>
		<ContentHeading >
			Our Locations
		</ContentHeading>
      <FooterRow>
        {LocationData.map((el, index) => (
          <FooterColumn key={index} style={{background: `linear-gradient(180deg, #fed693, #ca7100)`}}>
			<FooterRowAdd>
				<TextWrapper weight="400" size="1.3rem" mb="0.5rem">
				{el.town}
				</TextWrapper>
				<TextWrapper weight="600" size="1.3rem" mb="1rem" color="#ca7100" style={{textAlign:"center"}}>
				{el.title}
				</TextWrapper>
				{el.links.map((link, linkIndex) => (
				<TextWrapper key={linkIndex} >{link}</TextWrapper>
				))}
				
			</FooterRowAdd>
			<FooterRowAdd2>
      <TextWrapper weight="600" mb="0rem" color="#ca7100" style={{textAlign:"center"}}>
				Call us: <a href={el.tel_link} style={{color: "#fff", textDecoration:"none"}}>{el.tel_no}</a>
				</TextWrapper>
				<TextWrapper weight="300" mb="1rem" color="#ca7100" style={{textAlign:"center"}}>
				 <a href={el.email_link} style={{color: "#fff", textDecoration:"none"}}>{el.email}</a>
				</TextWrapper>
				</FooterRowAdd2>
			<FooterSocialIcon
			
				key={index}
				href={el.fb}
				target="_blank"
				aria-label={el.name}
				mt="0.5rem"
				>
				{el.icon}
			</FooterSocialIcon>
			<ContentButton >
						  <a
							target="_blank"
							href={el.fresha}
							style={{
							  textDecoration: "none",
							  color: "#fff",
							}}
						  >
							BOOK NOW
						  </a>
						</ContentButton>
          </FooterColumn>

        ))}
      </FooterRow>
    </Section>
  );
}

export default Location;
